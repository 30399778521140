import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import flattenChildren from "react-keyed-flatten-children"

import { Space } from "../types.js"

import "./Inline.css"

const Inline = ({ align, children, flex, gap, gapX, gapY, justify, wrap }) => {
  return (
    flattenChildren(children).length !== 0 && (
      <div
        className={classNames(`c-inline`, {
          [`c-inline--align-${align}`]: align,
          [`c-inline--flex-${flex}`]: flex,
          [`c-inline--justify-${justify}`]: justify,
          "c-inline--wrap": wrap,
          ...(gap
            ? { [`c-inline--gap-${gap}`]: gap }
            : {
                [`c-inline--gapX-${gapX}`]: gapX,
                [`c-inline--gapY-${gapY}`]: gapY,
              }),
        })}
      >
        {React.Children.map(flattenChildren(children), (child, key) => {
          return (
            <div className="c-inline__item" key={key}>
              {child}
            </div>
          )
        })}
      </div>
    )
  )
}

Inline.defaultProps = {
  align: `center`,
  wrap: true,
}

Inline.propTypes = {
  align: PropTypes.oneOf([`bottom`, `center`, `top`]),
  children: PropTypes.node.isRequired,
  flex: PropTypes.oneOf([`start`, `end`]),
  gap: PropTypes.oneOf(Space),
  gapX: PropTypes.oneOf(Space),
  gapY: PropTypes.oneOf(Space),
  justify: PropTypes.oneOf([`center`, `end`, `around`, `between`, `start`]),
  wrap: PropTypes.bool,
}

export { Inline }
