import React from "react"

import { Button, Inline, Inset } from "../"

const SocialLinks = () => {
  return (
    <Inset gap="xs">
      <Inline gap="2xs">
        <Button
          href="https://twitter.com/jon_milner"
          icon="social-twitter"
          label="Twitter"
          labelHidden={true}
          size="xs"
          variation="muted"
        />
        <Button
          href="https://www.linkedin.com/in/jonmilner/"
          icon="social-linkedin"
          label="LinkedIn"
          labelHidden={true}
          size="xs"
          variation="muted"
        />
        <Button
          href="https://github.com/jonmilner"
          icon="social-github"
          label="GitHub"
          labelHidden={true}
          size="xs"
          variation="muted"
        />
        <Button
          href="https://codepen.io/jonmilner"
          icon="social-codepen"
          label="CodePen"
          labelHidden={true}
          size="xs"
          variation="muted"
        />
      </Inline>
    </Inset>
  )
}

export { SocialLinks }
