import classNames from "classnames"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"

import { ClickableElement, Icon, Inline, Text, VisuallyHidden } from "../"

import "./Button.css"

const Button = forwardRef(({ active, icon, label, labelHidden, size, variation, ...props }, ref) => {
  return (
    <ClickableElement
      className={classNames(`c-button`, {
        [`c-button--icon-only`]: icon && labelHidden,
        [`c-button--size-${size}`]: size,
        [`c-button--variation-${variation}`]: variation,
        "is-active": active,
      })}
      ref={ref}
      {...(labelHidden && { title: label })}
      {...(props.to !== `/` && { activeClassName: `c-button--active` })}
      {...props}
    >
      <Inline gap="2xs" wrap={false}>
        {icon && <Icon name={icon} size={size} />}
        {!labelHidden && (
          <Text font="primary" size={size} weight="bold">
            {label}
          </Text>
        )}
      </Inline>
      {labelHidden && <VisuallyHidden>{label}</VisuallyHidden>}
    </ClickableElement>
  )
})

Button.displayName = `Button`

Button.defaultProps = {
  size: `med`,
  variation: `primary`,
}

Button.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelHidden: PropTypes.bool,
  size: PropTypes.oneOf([`lg`, `med`, `sm`, `xs`]),
  variation: PropTypes.oneOf([`primary`, `secondary`, `muted`, `nav`]),
}

export { Button }
