import React from "react"

import { Button, Emoji, Grid, GridColumn, Heading, Inline, Stack, Text, TextField } from "../"

import { SocialLinks } from "../SocialLinks/SocialLinks"

const Footer = ({ contact, theme }) => {
  return (
    <Stack gap="2xl">
      {contact && (
        <Stack gap="xl">
          <Stack gap="med">
            <Heading level={2} size="xl">
              Like what you see?
            </Heading>
            <Text theme="secondary" weight="bold">
              Say hello. <Emoji emoji="👋" label="wave" />
            </Text>
          </Stack>
          <form
            action="/success"
            autoComplete="on"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            id="form"
            method="post"
            name="contact"
          >
            <input name="form-name" type="hidden" value="contact" />
            <Stack gap="xl">
              <Stack gap="lg">
                <Grid breakpoint={400} col={2} gapX="med" gapY="lg">
                  <GridColumn span={1}>
                    <label className="c-field">
                      <Stack gap="sm">
                        <Text color="secondary" size="sm" weight="bold">
                          Name
                        </Text>
                        <TextField
                          autoCapitalize={true}
                          id="name"
                          name="name"
                          required={true}
                          spellCheck={false}
                          type="text"
                        />
                      </Stack>
                    </label>
                  </GridColumn>
                  <GridColumn span={1}>
                    <label className="c-field">
                      <Stack gap="sm">
                        <Text color="secondary" size="sm" weight="bold">
                          Email
                        </Text>
                        <TextField id="email" name="email" required={true} spellCheck={false} type="email" />
                      </Stack>
                    </label>
                  </GridColumn>
                </Grid>
                <label className="c-field">
                  <Stack gap="sm">
                    <Text color="secondary" size="sm" weight="bold">
                      Message
                    </Text>
                    <TextField id="message" multiline={true} name="message" rows={3} type="email" />
                  </Stack>
                </label>
              </Stack>
              <Inline gap="med">
                <Button label="Send" size="sm" type="submit" />
              </Inline>
            </Stack>
          </form>
        </Stack>
      )}
      <SocialLinks />
      <Text color="secondary" size="xs" weight="bold">
        Crafted with <Emoji emoji={theme === `light` ? `💚` : `💜`} label="love" /> and Gatsby. Hosted on Netlify.{` `}
        {/* <a href="https://github.com/jonmilner/jonmilner">
          View the source on GitHub.
        </a> */}
      </Text>
    </Stack>
  )
}

export { Footer }
