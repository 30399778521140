import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { VisuallyHidden } from "../"

import { TextColors, TextSizes } from "../Text/Text"

import "./Icon.css"

export const IconNames = [
  `airplane`,
  `arrow-right`,
  `eye`,
  `grid`,
  `light-off`,
  `light-on`,
  `lightbulb`,
  `ruler`,
  `social-codepen`,
  `social-github`,
  `social-linkedin`,
  `social-twitter`,
]

const Icon = ({ color, name, screenreaderLabel, size, style }) => {
  const getIconSvg = (name) => {
    const IconSvg = require(`!svg-react-loader!../../assets/icons/${name}.svg`)

    return (
      <div
        aria-hidden="true"
        className={classNames(`c-icon`, {
          [`c-icon--color-${color}`]: color,
          [`c-icon--size-${size}`]: size,
        })}
        style={style}
      >
        <IconSvg />
      </div>
    )
  }

  return (
    <>
      {getIconSvg(name)}
      {screenreaderLabel && <VisuallyHidden>{screenreaderLabel}</VisuallyHidden>}
    </>
  )
}

Icon.propTypes = {
  color: PropTypes.oneOf(TextColors),
  name: PropTypes.oneOf(IconNames),
  screenreaderLabel: PropTypes.string,
  size: PropTypes.oneOf(TextSizes),
  style: PropTypes.object,
}

export { Icon }
