import PropTypes from "prop-types"
import React from "react"
import flattenChildren from "react-keyed-flatten-children"

import { Icon, Inline, Stack, Text } from "../"

const List = ({ bulleted, children, ordered, size }) => {
  const length = children.length
  const numberLength = length.toString().length

  return (
    <Stack gap="med" list={ordered ? `ordered` : `unordered`}>
      {flattenChildren(children).length !== 0 &&
        React.Children.map(flattenChildren(children), (child, key) => {
          return (
            <Inline align="top" flex="end" gap="2xs" key={key} wrap={false}>
              {bulleted &&
                (ordered ? (
                  <Text
                    align="right"
                    aria-hidden={true}
                    size={size}
                    style={{
                      width: `${numberLength}em`,
                    }}
                    theme="brand"
                    weight="black"
                  >{`${key + 1}.`}</Text>
                ) : (
                  <Icon name="arrow-right" size={size} style={{ color: `var(--text-color--brand)` }} />
                ))}
              <Text size={size}>{child}</Text>
            </Inline>
          )
        })}
    </Stack>
  )
}

List.defaultProps = {
  bulleted: true,
  size: `sm`,
}

List.propTypes = {
  bulleted: PropTypes.bool,
  children: PropTypes.node.isRequired,
  ordered: PropTypes.bool,
  size: PropTypes.oneOf([`med`, `sm`]),
}

export { List }
