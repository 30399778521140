import PropTypes from "prop-types"
import React from "react"

import { Inline, Text } from "../"

import "./Checkbox.css"

const Checkbox = ({ checked, disabled, label, onChange, tabIndex }) => {
  return (
    <label className="c-checkbox">
      <Inline flex="end" gap="2xs" wrap={false}>
        <input
          aria-checked={checked}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          tabIndex={tabIndex}
          type="checkbox"
        />
        <Text size="xs" truncate={true} weight="bold">
          {label}
        </Text>
      </Inline>
    </label>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
}

export { Checkbox }
