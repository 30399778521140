import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { Space } from "../types.js"

import "./Block.css"

const Block = ({ children, padding, paddingX, paddingY }) => {
  return (
    <div
      className={classNames(`c-block`, {
        ...(padding
          ? { [`c-block--padding-${padding}`]: padding }
          : {
              [`c-block--paddingX-${paddingX}`]: paddingX,
              [`c-block--paddingY-${paddingY}`]: paddingY,
            }),
      })}
    >
      {children}
    </div>
  )
}

Block.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.oneOf(Space),
  paddingX: PropTypes.oneOf(Space),
  paddingY: PropTypes.oneOf(Space),
}

export { Block }
