import Link from "gatsby-link"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"

const ClickableElement = forwardRef(({ activeClassName, children, href, onClick, to, type, ...props }, ref) => {
  const commonProps = {
    ref: ref,
  }

  return to ? (
    <Link activeClassName={activeClassName} onClick={onClick} to={to} {...commonProps} {...props}>
      {children}
    </Link>
  ) : href ? (
    <a href={href} onClick={onClick} {...commonProps} {...props}>
      {children}
    </a>
  ) : onClick || type ? (
    <button onClick={onClick} type={type} {...commonProps} {...props}>
      {children}
    </button>
  ) : (
    <div {...commonProps} {...props}>
      {children}
    </div>
  )
})

ClickableElement.displayName = `ClickableElement`

ClickableElement.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  type: PropTypes.oneOf([`button`, `reset`, `submit`]),
}

export { ClickableElement }
