import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import flattenChildren from "react-keyed-flatten-children"

import { useResponsiveBreakpoints } from "../../utilities/useResponsiveBreakpoints"

import { Space } from "../types.js"

import "./Grid.css"

const Grid = ({ breakpoint, children, col, gap, gapX, gapY }) => {
  const ref = useRef(null)

  const display = useResponsiveBreakpoints(ref, [{ block: breakpoint - 1 }, { grid: breakpoint }])

  return (
    flattenChildren(children).length !== 0 && (
      <div
        className={classNames(`c-grid`, {
          [`c-grid--display-${display.breakSize}`]: breakpoint,
          ...(gap
            ? { [`c-grid--gap-${gap}`]: gap }
            : {
                [`c-grid--gapX-${gapX}`]: gapX,
                [`c-grid--gapY-${gapY}`]: gapY,
              }),
        })}
        ref={ref}
        style={{
          opacity: `${display.loading ? `0` : `1`}`,
          ...(col && { "--grid-columns": `${col}` }),
        }}
      >
        {children}
      </div>
    )
  )
}

Grid.propTypes = {
  breakpoint: PropTypes.number,
  children: PropTypes.node.isRequired,
  col: PropTypes.number,
  gap: PropTypes.oneOf(Space),
  gapX: PropTypes.oneOf(Space),
  gapY: PropTypes.oneOf(Space),
}

export { Grid }
