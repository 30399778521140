import PropTypes from "prop-types"
import React from "react"
import TextareaAutosize from "react-textarea-autosize"

import "./TextField.css"

const TextField = ({ autoCapitalize, id, multiline, name, required, rows, spellCheck, type, ...props }) => {
  const commonProps = {
    className: `c-textfield`,
    id: id,
    name: name,
    required: required,
  }

  return multiline ? (
    <TextareaAutosize {...commonProps} minRows={rows} type="text" {...props} />
  ) : (
    <input
      {...commonProps}
      autoCapitalize={autoCapitalize && `true` /* HTML autoCapitalize attribute expects a string */}
      inputMode={type === `number` ? `numeric` : type}
      spellCheck={spellCheck}
      type={type === `number` ? `text` : type}
      {...props}
    />
  )
}

TextField.defaultProps = {
  type: `text`,
}

TextField.propTypes = {
  autoCapitalize: PropTypes.bool,
  id: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  spellCheck: PropTypes.bool,
  type: PropTypes.oneOf([`email`, `number`, `password`, `tel`, `search`, `text`, `url`]),
}

export { TextField }
