import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import "./VisuallyHidden.css"

class VisuallyHidden extends React.Component {
  render() {
    const { children } = this.props

    return <span className={classNames(`visually-hidden`)}>{children}</span>
  }
}

VisuallyHidden.propTypes = {
  children: PropTypes.node.isRequired,
}

export { VisuallyHidden }
