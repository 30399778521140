import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import "./Container.css"

const Container = ({ align, children, size, width }) => {
  return (
    <div
      className={classNames(`c-container`, {
        [`c-container--align-${align}`]: align,
        [`c-container--size-${size}`]: size,
        [`c-container--width-${width}`]: width,
      })}
    >
      {children}
    </div>
  )
}

Container.defaultProps = {
  align: `left`,
  width: `med`,
}

Container.propTypes = {
  align: PropTypes.oneOf([`center`, `left`, `right`]),
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf([`xl`, `lg`, `med`, `sm`, `xs`]),
  width: PropTypes.oneOf([`xl`, `lg`, `med`, `sm`, `xs`]),
}

export { Container }
