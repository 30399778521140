import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { Space } from "../types"

import "./Inset.css"

const Inset = ({ children, gap, gapX, gapY }) => {
  return (
    <div
      className={classNames(`c-inset`, {
        [`c-inset--gap-${gap}`]: gap,
        [`c-inset--gapX-${gapX}`]: gapX,
        [`c-inset--gapY-${gapY}`]: gapY,
      })}
    >
      {children}
    </div>
  )
}

Inset.propTypes = {
  children: PropTypes.node.isRequired,
  gap: PropTypes.oneOf(Space),
  gapX: PropTypes.oneOf(Space),
  gapY: PropTypes.oneOf(Space),
}

export { Inset }
