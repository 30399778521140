import classNames from "classnames"
import { useMDXScope } from "gatsby-plugin-mdx/context"
import Highlight, { defaultProps } from "prism-react-renderer"
import React, { useContext } from "react"
import { LiveEditor, LiveError, LivePreview, LiveProvider } from "react-live"

import { Checkbox, Inline, Text } from "../"
import { GlobalDispatchContext, GlobalStateContext } from "../../context/GlobalContextProvider"

import { useLocalStorage } from "../../utilities/useLocalStorage"

import { VisuallyHidden } from "../VisuallyHidden/VisuallyHidden"

import theme from "./theme"

import "./CodeBlock.css"

function getParams(className = ``) {
  const [lang = ``, params = ``] = className.split(`:`)

  return [lang.split(`language-`).pop().split(`{`).shift()].concat(
    params.split(`&`).reduce((merged, param) => {
      const [key, value] = param.split(`=`)
      merged[key] = value
      return merged
    }, {})
  )
}

const RE = /{([\d,-]+)}/

const calculateLinesToHighlight = (meta) => {
  if (RE.test(meta)) {
    const lineNumbers = RE.exec(meta)[1]
      .split(`,`)
      .map((v) => v.split(`-`).map((y) => parseInt(y, 10)))
    return (index) => {
      const lineNumber = index + 1
      const inRange = lineNumbers.some(([start, end]) =>
        end ? lineNumber >= start && lineNumber <= end : lineNumber === start
      )
      return inRange
    }
  } else {
    return () => false
  }
}

const CodeBlock = ({ className: blockClassName, codeString, metastring, noInline, numbered = false, ...props }) => {
  const [codeTab, setCodeTab] = useLocalStorage(`codeTab`)

  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const scope = useMDXScope()

  const toggleCodeTab = () => {
    setCodeTab(!codeTab)
    dispatch({ type: `TOGGLE_CODE_TAB` })
  }

  const [language, { title = `` }] = getParams(blockClassName)
  const shouldHighlightLine = calculateLinesToHighlight(metastring)

  const showLineNumbers = true

  const hasLineNumbers = numbered && language !== `numbered` && showLineNumbers

  const codeBlockLive = (
    <>
      <LiveProvider
        code={codeString.trim()}
        noInline={noInline}
        scope={scope}
        style={{ overflow: `hidden` }}
        theme={theme}
      >
        <div className="c-code-block__options">
          <Checkbox checked={state.codeTab} label="Use tab to indent" onChange={() => toggleCodeTab(!state.codeTab)} />
        </div>
        <label className="c-code-block__editor-wrapper">
          <VisuallyHidden>Edit text</VisuallyHidden>
          <LiveEditor
            className="c-code-block__editor prism-code"
            ignoreTabKey={!state.codeTab}
            onKeyDown={(e) => {
              if (e.keyCode === 27) {
                toggleCodeTab(false)
                e.preventDefault()
              }
            }}
          />
        </label>
        <LiveError className="live-error" />
        <div className="live-preview-wrapper">
          <LivePreview className="live-preview" />
        </div>
      </LiveProvider>
    </>
  )

  const codeBlockHighlight = (
    <div className="c-code-block__editor-wrapper">
      <Highlight {...defaultProps} code={codeString} language={language} theme={theme}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} data-linenumber={hasLineNumbers} data-lines={tokens.length} style={{ ...style }}>
            {tokens.map((line, index) => {
              if (
                line.length === 1 &&
                line[0].content === `` &&
                index < tokens.length - 1 // skips the ending empty line
              ) {
                line[0].content = ` ` // preserves empty line for copy/paste
              }

              const lineProps = getLineProps({ line, key: index })

              if (shouldHighlightLine(index)) {
                lineProps.className = `${lineProps.className} highlight-line`
              }

              return (
                <div key={index} {...lineProps}>
                  {hasLineNumbers && <span className="line-number-style">{index + 1}</span>}
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token, key })} />
                  ))}
                </div>
              )
            })}
          </pre>
        )}
      </Highlight>
    </div>
  )

  const LanguageLabel = ({ image, label }) => {
    if (image || label) {
      return (
        <div className={classNames(`c-code-block__lang`, {})} title={label}>
          <Inline align="center" gap="2xs">
            {image && <img alt={`${label} Logo`} src={image} style={{ height: `1rem`, width: `1rem` }} />}
            {label && (
              <Text format="uppercase" size="2xs" weight="bold">
                {label}
              </Text>
            )}
          </Inline>
        </div>
      )
    } else {
      return null
    }
  }

  const getLanguageData = (language) => {
    switch (language) {
      case `js`:
        return {
          image: require(`../../assets/images/codelang/javascript.webp`),
          label: `JavaScript`,
        }
      case `jsx` || `tsx`:
        return {
          image: require(`../../assets/images/codelang/react.webp`),
          label: `React`,
        }
      case `html`:
        return {
          image: require(`../../assets/images/codelang/html.webp`),
          label: `HTML`,
        }
      case `css`:
        return {
          image: require(`../../assets/images/codelang/css.webp`),
          label: `CSS`,
        }
      case `markdown`:
        return {
          image: require(`../../assets/images/codelang/md.webp`),
          label: `Markdown`,
        }
      default:
        return
    }
  }

  const label = getLanguageData(language)?.label

  return (
    <div
      className={classNames(`c-code-block`, {
        "c-code-block--live": props[`live`],
      })}
    >
      {(label || title) && (
        <div className="c-code-block__header">
          <Inline gap="2xs">
            {language && (
              <LanguageLabel
                image={getLanguageData(language)?.image?.default}
                label={getLanguageData(language)?.label}
              />
            )}
            {title && (
              <div className="c-code-block__title">
                <Text size="xs">{title}</Text>
              </div>
            )}
          </Inline>
        </div>
      )}
      {props[`live`] ? codeBlockLive : codeBlockHighlight}
    </div>
  )
}

export { CodeBlock }
