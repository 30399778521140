import classNames from "classnames"
import Link from "gatsby-link"
import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"

import "focus-visible"

import { Block, Button, Container, Footer, Hr, Inline, Stack, Text } from "../"
import { GlobalDispatchContext, GlobalStateContext } from "../../context/GlobalContextProvider"

import { useLocalStorage } from "../../utilities/useLocalStorage"

import "../../assets/css/styles.css"

import "./Layout.css"

const removeClassByPrefix = (node, prefix) => {
  var regx = new RegExp(`\\b` + prefix + `[^ ]*[ ]?\\b`, `g`)
  node.className = node.className.replace(regx, ``)
  return node
}

const Layout = ({ children, contact = true }) => {
  const [blueprintMode, setBlueprintMode] = useLocalStorage(`blueprintMode`)
  const [theme, setTheme] = useLocalStorage(`theme`)

  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const toggleBlueprintMode = () => {
    setBlueprintMode(!state.blueprintMode)
    dispatch({ type: `TOGGLE_BLUEPRINT_MODE` })
  }

  const toggleTheme = () => {
    setTheme(state.theme === `light` ? `dark` : `light`)
    dispatch({ type: `TOGGLE_THEME` })
  }

  useEffect(() => {
    if (state.blueprintMode) {
      document.documentElement.classList.add(`blueprint-mode`)
    } else {
      document.documentElement.classList.remove(`blueprint-mode`)
    }
  }, [state.blueprintMode])

  useEffect(() => {
    removeClassByPrefix(document.documentElement, `theme-`)

    document.documentElement.classList.add(`theme-${state.theme}`)
  }, [state.theme])

  return (
    <div className={classNames(`c-layout`)}>
      <Helmet>
        <link
          rel="icon"
          // href={require("../../assets/images/icon.svg")}
          sizes="any"
          type="image/png"
        />
      </Helmet>
      <header className="c-header">
        <Block paddingY="xs">
          <Container align="center" width="sm">
            <Inline justify="between">
              <Text size="lg" weight="black">
                <Link to="/">JM</Link>
              </Text>
              <Inline gap="2xs" justify="end">
                <Button
                  active={theme === `light`}
                  icon="lightbulb"
                  label="Toggle Theme"
                  labelHidden={true}
                  onClick={() => toggleTheme()}
                  size="med"
                  variation="muted"
                />
                <Button
                  active={blueprintMode}
                  icon={blueprintMode ? `eye-off` : `eye`}
                  label="Toggle Outlines"
                  labelHidden={true}
                  onClick={() => toggleBlueprintMode()}
                  size="med"
                  variation="muted"
                />
              </Inline>
            </Inline>
          </Container>
        </Block>
      </header>
      <main className="c-main">
        <Container align="center" width="sm">
          <Block paddingY="4xl">
            <Stack gap="3xl">
              {children}
              <Hr />
              <Footer contact={contact} theme={state.theme === `light` ? `light` : `dark`} />
            </Stack>
          </Block>
        </Container>
      </main>
    </div>
  )
}

export { Layout }
