/* eslint-disable sort-keys */

var theme = {
  plain: {
    color: `var(--text-color--secondary)`,
    backgroundColor: `transparent`,
  },
  styles: [
    {
      types: [`comment`],
      style: {
        color: `var(--text-color--tertiary)`,
        fontStyle: `italic`,
      },
    },
    {
      types: [`keyword`, `operator`, `selector`, `changed`],
      style: {
        color: `var(--code-purple)`,
      },
    },
    {
      types: [`constant`, `number`, `builtin`, `attr-name`],
      style: {
        color: `var(--code-orange)`,
      },
    },
    {
      types: [`char`, `symbol`],
      style: {
        color: `var(--code-teal)`,
      },
    },
    {
      types: [`variable`, `tag`, `deleted`],
      style: {
        color: `var(--code-red)`,
      },
    },
    {
      types: [`attr-value`, `string`, `inserted`],
      style: {
        color: `var(--code-green)`,
      },
    },
    {
      types: [`punctuation`],
      style: {
        color: `var(--text-color--muted)`,
      },
    },
    {
      types: [`function`],
      style: {
        color: `var(--code-blue)`,
      },
    },
    {
      types: [`class-name`],
      style: {
        color: `var(--code-yellow)`,
      },
    },
  ],
}

module.exports = theme
