import PropTypes from "prop-types"
import React from "react"

import "./Emoji.css"

const Emoji = ({ emoji, label }) => {
  return (
    <span
      className="c-emoji"
      role="img"
      {...(label && { "aria-label": label, title: label })}
      {...(label === undefined && { "aria-hidden": true })}
    >
      {emoji}
    </span>
  )
}

Emoji.propTypes = {
  emoji: PropTypes.string.isRequired,
  label: PropTypes.string,
}

export { Emoji }
