import { useLayoutEffect, useRef, useState } from "react"
import ResizeObserver from "resize-observer-polyfill"

const findBreakPoint = (breakpoints, width) => {
  const breakpointIndex = breakpoints.map((x) => Object.values(x)[0]).findIndex((x) => width < x)

  if (breakpointIndex === -1) {
    return Object.keys(breakpoints[breakpoints.length - 1])[0]
  }

  return Object.keys(breakpoints[breakpointIndex])[0]
}

const useResponsiveBreakpoints = (elRef, breakpoints) => {
  const firstQuery = Object.keys(breakpoints[0])[0]
  const [breakSize, setBreakSize] = useState(firstQuery)
  const [loading, setLoading] = useState(true)

  const observer = useRef(
    new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        const { width } = entries[0].contentRect
        setBreakSize(findBreakPoint(breakpoints, width))
      })
    })
  )

  useLayoutEffect(() => {
    const _observer = observer?.current
    const _element = elRef?.current

    elRef?.current && _observer.observe(_element)

    setLoading(false)

    return () => {
      _observer.unobserve(_element)
    }
  }, [elRef, observer])

  return { breakSize, loading }
}

export { useResponsiveBreakpoints }
