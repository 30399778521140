import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import flattenChildren from "react-keyed-flatten-children"

import { Space } from "../types.js"

import "./Stack.css"

const Stack = ({ align, children, gap, justify, list }) => {
  const StackElement = list ? (list === `ordered` ? `ol` : `ul`) : `div`
  const StackItemElement = list ? `li` : `div`

  return (
    flattenChildren(children).length !== 0 && (
      <StackElement
        className={classNames(`c-stack`, {
          [`c-stack--align-${align}`]: align,
          [`c-stack--gap-${gap}`]: gap,
          [`c-stack--justify-${justify}`]: justify,
        })}
      >
        {React.Children.map(flattenChildren(children), (child, key) => {
          if (child !== null) {
            return (
              <StackItemElement className={classNames(`c-stack__item`)} key={key}>
                {child}
              </StackItemElement>
            )
          }
        })}
      </StackElement>
    )
  )
}

Stack.propTypes = {
  align: PropTypes.oneOf([`center`]),
  children: PropTypes.node.isRequired,
  gap: PropTypes.oneOf(Space),
  justify: PropTypes.oneOf([`end`]),
  list: PropTypes.oneOf([`ordered`, `unordered`]),
}

export { Stack }
