import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const GridColumn = ({ children, span }) => {
  return (
    <div
      className={classNames(`c-grid__column`, {
        [`c-grid__column--span-${span}`]: span,
      })}
      style={{
        ...(span && { gridColumnEnd: `span ${span}` }),
      }}
    >
      {children}
    </div>
  )
}

GridColumn.propTypes = {
  children: PropTypes.node,
  span: PropTypes.number,
}

export { GridColumn }
