import React from "react"

import "./Hr.css"

const Hr = () => {
  return (
    <div className="hr">
      <div className="hr__gradient" />
      <div className="hr__gradient" />
      <div className="hr__gradient" />
      <div className="hr__gradient" />
    </div>
  )
}

export { Hr }
