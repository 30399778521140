/* eslint-disable react/display-name */
/* eslint-disable sort-keys */
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { preToCodeBlock } from "mdx-utils"
import React from "react"

import { CodeBlock, Heading, Hr, List, Stack, Text } from "../"

const components = {
  // Main Wrapper
  wrapper: ({ children }) => <Stack gap="2xl">{children}</Stack>,

  // Headings
  h1: ({ children }) => (
    <Heading level={2} size="2xl">
      {children}
    </Heading>
  ),
  h2: ({ children }) => (
    <Heading level={3} size="xl">
      {children}
    </Heading>
  ),
  h3: ({ children }) => (
    <Heading level={4} size="lg">
      {children}
    </Heading>
  ),

  // Lists
  ol: (props) => <List ordered={true} {...props} />,
  ul: (props) => <List {...props} />,
  li: (props) => <span {...props} />,

  // Paragraph
  p: (props) => <Text {...props} />,
  hr: () => <Hr />,

  // Code
  pre: (preProps) => {
    const props = preToCodeBlock(preProps)

    if (props) {
      return <CodeBlock {...props} />
    }

    return <pre {...preProps} />
  },

  // Tables
  table: (props) => <table {...props} />,
  th: ({ children }) => (
    <th>
      {typeof children === `string` ? (
        <Text truncate={true} weight="bold">
          {children}
        </Text>
      ) : (
        children
      )}
    </th>
  ),
  td: ({ children }) => <td>{typeof children === `string` ? <Text truncate={true}>{children}</Text> : children}</td>,
}

const Content = ({ children }) => {
  return (
    <MDXProvider components={components} disableParentContext={true}>
      <MDXRenderer>{children}</MDXRenderer>
    </MDXProvider>
  )
}

export { Content }
