import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import "./Text.css"

export const TextAlign = [`center`, `left`, `right`]
export const TextColors = [`primary`, `secondary`, `active`]
export const TextFormat = [`capitalize`, `lowercase`, `uppercase`]
export const TextSizes = [`4xl`, `3xl`, `2xl`, `xl`, `lg`, `med`, `sm`, `xs`, `2xs`, `3xs`]
export const TextThemes = [`primary`, `secondary`, `brand`]
export const TextWeights = [`regular`, `bold`, `black`]

const StyledTextClamp = styled.div`
  -webkit-line-clamp: ${(props) => props.clamp};
`

const Text = ({
  align,
  children,
  clamp,
  color,
  element,
  format,
  heading,
  highlightLinks,
  letterSpacing,
  size,
  theme,
  truncate,
  weight,
  ...props
}) => {
  const Element = element

  return (
    <Element
      className={classNames(`c-text`, {
        [`c-text--align-${align}`]: align,
        [`c-text--color-${color}`]: color,
        [`c-text--format-${format}`]: format,
        [`c-text--heading`]: heading,
        [`c-text--highlight-links`]: highlightLinks,
        [`c-text--letter-spacing-${letterSpacing}`]: letterSpacing,
        [`c-text--size-${size}`]: size,
        [`c-text--theme-${theme}`]: theme,
        [`c-text--weight-${weight}`]: weight,
      })}
      {...props}
    >
      {truncate ? (
        <div className="c-text__truncate">{children}</div>
      ) : clamp ? (
        <StyledTextClamp clamp={clamp} className="c-text__clamp">
          {children}
        </StyledTextClamp>
      ) : (
        children
      )}
    </Element>
  )
}

Text.defaultProps = {
  element: `div`,
  size: `med`,
}

Text.propTypes = {
  align: PropTypes.oneOf(TextAlign),
  children: PropTypes.node,
  clamp: PropTypes.number,
  color: PropTypes.oneOf(TextColors),
  element: PropTypes.oneOf([`h1`, `h2`, `h3`, `h4`, `h5`, `h6`, `p`, `div`]),
  format: PropTypes.oneOf(TextFormat),
  highlightLinks: PropTypes.bool,
  letterSpacing: PropTypes.oneOf([`narrow`, `default`, `wide`]),
  size: PropTypes.oneOf(TextSizes),
  theme: PropTypes.oneOf(TextThemes),
  truncate: PropTypes.bool,
  weight: PropTypes.oneOf(TextWeights),
}

export { Text }
