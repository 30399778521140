import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import "./Blockquote.css"

class Blockquote extends React.Component {
  render() {
    const { children, cite } = this.props

    return (
      <blockquote cite={cite} className={classNames(`blockquote`)}>
        {children}
      </blockquote>
    )
  }
}

Blockquote.propTypes = {
  children: PropTypes.node.isRequired,
  cite: PropTypes.string.isRequired,
}

export { Blockquote }
