import PropTypes from "prop-types"
import React from "react"

import { Text } from "../"

const Heading = ({ children, level, ...props }) => {
  return (
    <Text font="primary" heading={true} weight="black" {...(level && { element: `h${level}` })} {...props}>
      {children}
    </Text>
  )
}

Heading.defaultProps = {
  letterSpacing: `narrow`,
  size: `4xl`,
  weight: `black`,
}

Heading.propTypes = {
  children: PropTypes.node,
  level: PropTypes.oneOf([1, 2, 3, 4, null]),
}

export { Heading }
