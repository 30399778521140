import { useEffect, useState } from "react"

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    if (typeof window === `undefined`) {
      return
    }

    const storedValue = localStorage.getItem(key)

    return storedValue === null ? defaultValue : JSON.parse(storedValue)
  })

  useEffect(() => {
    const listener = (e) => {
      if (e.storedArea === localStorage && e.key === key) {
        setValue(JSON.parse(e.newValue))
      }
    }

    window.addEventListener(`storage`, listener)

    return () => {
      window.removeEventListener(`storage`, listener)
    }
  }, [key])

  const setLocal = (newValue) => {
    setValue((currentValue) => {
      const result = typeof newValue === `function` ? newValue(currentValue) : newValue
      localStorage.setItem(key, JSON.stringify(result))
      return result
    })
  }

  return [value, setLocal]
}
